

import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import { FetchPolicy } from 'apollo-client';
import DataTableHandler from '@/components/DataTable/DataTable';
import Message from '@/components/mixins/Message.vue';
import { isEmptyArray } from '@/helpers/arrayHelpers';

@Component({
  name: 'DashboardContactModuleBlock',
  components: {
    DataTable,
    BaseCard,
  },
  methods: {
    isEmptyArray,
  },
})
export default class DashboardContactModuleBlock extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  private readonly title!: string;

  private totalResultCounter = '';

  private hasExport = false;

  private dataTable = new DataTableHandler('DashboardContactModuleBlock');

  private loading = false;

  mounted(): void {
    this.fetchData();
  }

  private fetchData(fetchPolicy: FetchPolicy = 'no-cache'): void {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      blockId: this.blockId,
    };

    import('@/graphql/queries/dashboard-module-block')
      .then(({ default: query }) => this.$apollo.query({
        query,
        fetchPolicy,
        variables,
      }))
      .then((response) => {
        this.dataTable.handleData(response.data);
        this.totalResultCounter = `(${this.dataTable.total})`;
        this.hasExport = response.data.list.dataGridInfo.metadata['list-settings']?.export ?? false;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private exportFile(): void {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      blockId: this.blockId,
    };

    import('@/graphql/queries/export-task-list-by-dossier-id')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
      }))
      .then((response) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.export.data}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.export.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

